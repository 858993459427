.header-container{
  background-color: #141414;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 10;

  .buttons-layout{
    display: flex;
    margin-right: 10px;

    .icon{
      width: 30px;
      opacity: 0.4;
      cursor: pointer;
    }
  }

  .user-button{
    width: 30px;
    margin-right: 10px;
    cursor: pointer;
  }

  .site-name-container{
    display: flex;
    align-items: center;

    img{
      width: 30px;
      margin-left: 10px;
    }
  }

  .search-container{
    width: 50%;
    display: flex;
    position: relative;
    margin-right: 5px;

    .icon-container{
      width: 20px;
      justify-content: center;
      align-items: center;
      display: flex;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 5px;

      img{
        width: 100%;
        opacity: 0.5;
      }
    }

    input{
      width: 100%;
      padding-left: 30px;
    }
  }

  h2{
    padding: 5px;
  }
}