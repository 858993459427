.form-container{
  background-color: #0d0d0d;
  padding: 10px;
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  box-shadow: 3px 3px 10px rgba(0,0,0,0.3);

  .header {
    display: flex;
    align-items: center;

    img{
      width: 50px;
      margin: 10px 10px 10px 0;
    }
  }

  button{
    align-self: flex-end;
  }
}