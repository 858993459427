.details-container{
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;

  .stage-item {
    background-color: #1c1c1c;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;

    &:hover{
      .task-action-button{
        opacity: 1;
      }
    }
  }

  .task-action-button {
    background-color: #1c1c1c;
    color: #767676;
    padding: 2px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
    opacity: 0;
  }

  .divider-layout {
    display: flex;
    align-items: center;

    margin: 10px 0;

    hr{
      width: 100%;
      margin-left: 10px;
    }
  }

  .header {
    background-color: #141414;
    align-self: flex-start;
    display: flex;
    align-items: center;

    a {
      align-self: flex-start;
    }

    .asset-name-layout {
      margin: 5px 30px 5px 10px;
    }

    .close {
      justify-self: flex-start;
      align-self: flex-start;
      width: 12px;
      margin: 5px 5px 0 30px;
    }

    .asset-name {
      color: #00b8f8;
      margin-right: 10px;
    }
  }

  .main-columns {
    display: grid;
    grid-template-columns: 280px auto;
    grid-gap: 10px;
    background-color: #141414;
    padding: 5px;
    position: absolute;
    bottom: 0;
    top: 52px;
    left: 0;
    right: 0;
  }

  .left-column{
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .thumbnail-container {
      background-color: black;
      width: 100%;
      height: 350px;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }
    }

    .tags-container{
      display: flex;
      flex-wrap: wrap;

      .tag {
        border-radius: 5px;
        padding: 3px 5px;
        font-size: 12px;
        display: flex;

        img {
          width: 18px;
          margin-right: 5px;
        }
      }
    }
  }

  .center-column {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .concept-layout{
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      overflow-y: auto;

      .thumbnail{
        width: 100%;
        height: 200px;
        border-radius: 10px;
        cursor: pointer;
        background-color: black;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .concept-viewer-container {
        position: fixed;
        top: 39px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: black;
        display: flex;
        flex-direction: column;
        padding: 10px;

        .icon-button{
          align-self: flex-end;
          width: 16px;
        }

        .preview-container {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: none;

          .preview {
            max-height: 100%;
            max-width: 100%;
            border-radius: 10px;
          }
        }

        .concept-navigator-layout{
          display: flex;
          position: absolute;
          bottom: 0;
          justify-content: center;
          background-color: rgba(0,0,0,0.7);
          left: 0;
          right: 0;
          padding: 10px;

          .dot {
            background-color: rgba(255,255,255, 0.2);
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin: 0 5px;
            cursor: pointer;
            transition: all 0.3s;

            &:hover{
              background-color: rgba(255,255,255, 0.5);
            }
          }

          .current {
            background-color: white;
          }
        }
      }
    }
  }

  .right-column {
    background-color: #0d0d0d;
    overflow-y: auto;
  }
}