.asset-list-grid-container{
  position: relative;
}

.asset-list-grid{
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  overflow-y: scroll;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 2;
  background-color: rgba(0,0,0,0.5);
  opacity: 0;
  transition: all 0.3s;
  pointer-events: none;
}

.loading{
  opacity: 1;
}

.asset-card {
  width: 100%;
  height: 280px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 0.3s;

  &:hover{
    background-color: rgba(255,255,255,0.05);

  }

  .content{
    display: flex;
    flex-direction: column;

    .thumbnail-container {
      background-color: #252525;
      height: 230px;
      width: 250px;
      border-radius: 26px;
      box-shadow: 3px 3px 10px rgba(0,0,0,0.3);
      position: relative;
      margin-bottom: 5px;
      display: flex;
      flex-direction: column;
      background-size: cover;
      background-position: center;

      .spinner-container{
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
      }

      .priority-icon {
        align-self: flex-end;
        width: 40px;
      }

      .no-thumbnail{
        width: 50%;
        opacity: 0.1;
        align-self: center;
      }

      .footer{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 33px;
        display: flex;
        flex-direction: column;
        border-radius: 0 0 26px 26px;
        overflow: hidden;

        small{
          padding: 0 10px 0 0;
          color: white;
          width: 100%;
          height: 30px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          background: linear-gradient(transparent, black);
        }

        .state-color{
          width: 100%;
          background-color: red;
          height: 20px;
        }
      }
    }
  }
}