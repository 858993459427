@import "Header";
@import "Login";
@import "Home";
@import "SidePanel";
@import "AssetList";
@import "Scrollbar";
@import "Details";
@import "CheckOutDialog";
@import "UserDialog";

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-family: 'Roboto', sans-serif;
  background-color: #1C1C1C;
  color: #767676;
}

h1, h2 {
  font-weight: 300;
}

h3, h4{
  font-weight: 400;
}

.content-container {
  padding-top: 45px;
}

.center-page {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

button{
  border: none;
  padding: 5px 20px;
  border-radius: 10px;
  background-color: #1D96FE;
  color: white;
  margin: 5px 0;
  cursor: pointer;
  transition: all 0.3s;
  outline: none;

  &:hover{
    background-color: #46a8f8;
  }
}

input, textarea {
  background-color: #0d0d0d;
  border: none;
  font: inherit;
  color: inherit;
  outline: none;
  margin: 10px 0;
  border-radius: 10px;
  padding: 3px 10px;
  transition: color 0.3s;

  &:focus{
    color: white;
  }
}

textarea{
  width: 100%;
  resize: vertical;
}

hr{
  border: none;
  border-bottom: 1px solid rgba(255,255,255, 0.1);
  margin: 10px 0
}

a {
  text-decoration: none;
  color: #767676;

  &:hover{
    color: white;
  }
}

.icon-button{
  width: 20px;
  opacity: 0.5;
  margin-right: 10px;
  cursor: pointer;

  &:hover{
    opacity: 0.8;
  }
}

.active-icon{
  fill: #1D96FE;
  opacity: 1;
}

.inactive-button{
  cursor: inherit;
}