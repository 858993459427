.check-out-container{
  position: fixed;
  background-color: rgba(0,0,0,0.7);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: inherit;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  .check-out-dialog {
    background-color: #0d0d0d;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 26px;
    min-width: 500px;

    .drop-area {
      min-height: 100px;
      border-radius: 10px;
      background-color: black;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 3px dashed rgba(255,255,255,0.2);

      .file-name-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      button{
        align-self: center;
      }
    }

    .header{
      width: 100%;
      background-color: transparent;
      display: flex;
      justify-content: space-between;
    }

    .attachment{
      display: flex;
      flex-direction: column;
    }

    .error {
      color: red;
      align-self: center;
    }

    .upload_progress_container{
      width: 100%;
      height: 37px;
      display: flex;
      position: relative;

      .progressbar{
        width: 100%;
        height: 100%;
        background-color: #1D96FE;
        border-radius: 10px;
        overflow: hidden;
      }

      .progress-text{
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffffa8;
      }
    }

    button{
      align-self: flex-end;
    }
  }
}