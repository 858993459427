$size: 200px;

.my-task-list{
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 600px;

  .task-item{
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 80px auto;
    margin: 5px 0;
    cursor: pointer;
    transition: all 0.3s;

    h2{
      padding: 0;
    }

    &:hover{
      background-color: rgba(255,255,255,0.05);
    }

    .thumbnail-container{
      width: 100%;
      height: 90px;
      background-position: center;
      background-size: cover;
      border-radius: 5px;
    }

    .details{
      display: flex;
      flex-direction: column;
    }
  }
}