.side-panel-container{
  background-color: #0d0d0d;
  border-radius: 0 26px 26px 0;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .arrow{
    width: 7px;
    align-self: center;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .down{
    transform: rotate(-90deg);
  }
}

.category-box {
  border-bottom: 1px solid rgba(255,255,255, 0.1);
  margin-bottom: 20px;
  padding-bottom: 10px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    cursor: pointer;
    user-select: none;

    .icon{
      width: 20px;
      margin-right: 10px;
      opacity: 0.4;
    }

    .arrow{
      width: 7px;
    }

    .down{
      transform: rotate(-90deg);
    }
  }

  .body{
    font-size: 12px;
    overflow: hidden;
    display: none;

    .item-container{
      display: flex;
      cursor: pointer;
      user-select: none;

      .white-box{
        min-width: 5px;
        background-color: white!important;
        opacity: 0;
      }

      .selected{
        opacity: 1;
      }

      .item {
        padding-left: 20px;
      }
    }
  }

  .selected {
    background-color: rgba(255,255,255, 0.1);
  }

  .opened{
    display: block;
  }
}